import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false
        });
    }, []);

    const featuredProducts = [
        {
            name: "Rainbow Cupcake",
            description: "Colorful vanilla cupcake with buttercream frosting",
            image: "/images/cupcake.jpg"
        },
        // Add more products...
    ];

    return (
        <div className="home-wrapper">
            <div className="hero-banner">
                <div className="hero-overlay"></div>
                <Container className="hero-content">
                   

                    <h1 className="hero-title" data-aos="fade-up">
                        Raw Da' Bun
                    </h1>
                    <p className="hero-subtitle" data-aos="fade-up" data-aos-delay="200">
                        Where Every Bite is a Sweet Memory
                    </p>
                    <Link
                        to="products"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={100}
                    >
                        <Button
                            variant="custom-pink"
                            size="lg"
                            className="hero-button"
                            data-aos="zoom-in"
                            data-aos-delay="700"
                        >
                            Descoperă Produsele Noastre
                        </Button>
                    </Link>
                </Container>
            </div>
        </div>
    );
}

export default Home;