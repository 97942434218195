export const productDescriptions = {
    "Tort cu Caramel Sărat și Ciocolată": {
        description: "Tortul cu Caramel Sărat și Ciocolată este o creație ce îmbină armonios savoarea caramelului sărat cu ciocolata fina. Potrivit pentru momente speciale sau pentru a satisface dorințele iubitorilor de ciocolată și caramel, acest tort elegant aduce un strop de rafinament oricărei mese.",
        ingredients: [
            "Curmale",
            "caju",
            "cocos",
            "sirop de arțar",
            "ulei de cocos extravirgin",
            "unt de cacao",
            "seminte de in",
            "pudra de cacao raw",
            "extract de vanilie",
            "pudra de mesquite",
            "sare"
        ],
        variants: [
            {
                name: "Standard",
                weight: "850 gr",
                price: "250,00 Lei",
                pricePerUnit: "30 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },

    "Tort cu Caramel Sărat și Migdale": {
        description: "Descoperiți Tortul cu Caramel Sărat și Migdale, o delicatesă supremă care se topește în gură. Fiecare felie combină perfect caramelul sărat cu migdalele aromate, oferind o experiență gustativă de neegalat. Ideal pentru iubitorii de caramel, acest tort rafinat promite să răsfețe papilele gustative și să aducă un plus de eleganță oricărei ocazii.",
        ingredients: [
            "Curmale",
            "sirop de arțar",
            "caju",
            "ulei de cocos extravirgin",
            "migdale",
            "pasta vegetala de migdale",
            "băutura de migdale",
            "extract pur de vanilie",
            "pudra mesquite",
            "sare",
            "scortisoara Ceylon"
        ],
        variants: [
            {
                name: "Standard",
                weight: "1.500 gr",
                price: "450,00 Lei",
                pricePerUnit: "30 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },

    "Tort Trio de Ciocolată": {
        description: "Tortul Trio de Ciocolată este un răsfăț suprem pentru iubitorii de ciocolată, combinând perfect straturi fine de ciocolată neagră. Fiecare bucățică oferă o explozie de arome bogate și texturi catifelate, create pentru a satisface cele mai exigente gusturi. Un desert sofisticat, ideal pentru cei care caută perfecțiunea ciocolatei într-o singură felie.",
        ingredients: [
            "Caju",
            "pasta vegetala de cacao raw",
            "sirop de arțar",
            "ulei de cocos",
            "pasta de cacao",
            "băutura de ovăz",
            "pudra de cacao raw",
            "nuci pecane raw",
            "curmale",
            "extract pur de vanilie",
            "sare"
        ],
        variants: [
            {
                name: "Forma de inima",
                weight: "1 KG",
                price: "300 lei",
                pricePerUnit: "30 lei/100gr"
            },
            {
                name: "Tort rotund 15 CM",
                weight: "900-1100 gr",
                price: "270-330 lei",
                pricePerUnit: "30 lei/100gr"
            },
            {
                name: "Tort rotund 20 CM",
                weight: "1200-1600 gr",
                price: "360-480 lei",
                pricePerUnit: "30 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },
    "Tiramisu": {
        description: "Tortul Tiramisu Vegan combină rafinamentul tradițional al tiramisului cu ingrediente 100% vegetale, oferind o experiență deosebită și cremoasă. Fiecare strat este îmbogățit cu arome delicate de cafea și cacao, perfect echilibrate, pentru un desert elegant și savuros. Ideal pentru cei care caută o alternativă vegană deosebită.",
        ingredients: [
            "curmale",
            "nuci",
            "caju crud",
            "sirop de arțar",
            "espresso",
            "pasat vegetala de cocos",
            "bautura din plante",
            "ulei de cocos extravirgin",
            "extract pur de vanilie",
            "cafea",
            "cacao",
            "sare"
        ],
        variants: [
            {
                name: "Standard",
                weight: "1 KG",
                price: "300,00 Lei",
                pricePerUnit: "30 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },
    "Tort Mickey Mouse": {
        description: "Tortul în formă de Mickey Mouse, cu aromă de Cookie and Cream, este special creat pentru copii cu vârste de peste 6 luni. Designul vesel și gustul delicat de biscuiți și cremă sunt perfect adaptate pentru a încânta micii aventurieri. Un desert jucăuș și sofisticat, ideal pentru a celebra momente speciale în compania celor mici.",
        ingredients: [
            "caju crud",
            "sirop de arțar",
            "cacao",
            "ulei de cocos extravirgin",
            "pasta vegetala de cocos",
            "pasta vegetala de cacao",
            "pasta de cacao",
            "curmale",
            "pudra din cărbune activ de cocos",
            "extract pur de vanilie",
            "sare"
        ],
        variants: [
            {
                name: "Standard",
                weight: "900 gr",
                price: "270,00 Lei",
                pricePerUnit: "30 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării.",
            "La cerere, se pot adauga ochii creati din pasta de zahar."
        ]
    },
    "Tort Zebra cu Afine": {
        description: "Tortul Zebra cu Afine este alegerea ideală pentru cei care iubesc combinațiile rafinate de gusturi. Straturile de cremă de vanilie și afine proaspete se împletesc într-un design elegant de zebra, oferind un gust echilibrat și revigorant. Perfect pentru ocazii speciale, acest tort aduce un plus de eleganță și savoare.",
        ingredients: [
            "caju",
            "afine",
            "mix de fructe uscate",
            "sirop artar",
            "ulei de cocos",
            "lamaie",
            "băutură de cocos",
            "pasta vegetala de cacao",
            "cocos",
            "extract pur de vanilie",
            "sare"
        ],
        variants: [
            {
                name: "Standard",
                weight: "1.250 gr",
                price: "370,00 Lei",
                pricePerUnit: "30 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },
    "Tort Zebra cu Fistic și Ciocolată Albă - KETO": {
        description: "Tortul Zebra cu Fistic și Ciocolată Albă reprezintă alegerea perfectă pentru pasionații de ciocolată albă și fistic care urmează dieta keto. Acest desert rafinat îmbină straturi delicate de ciocolată albă și crema de fistic într-un design elegant de zebra, oferind o experiență gustativă luxuriantă și conformă cu principiile dietetice. Ideal pentru a savura un deliciu sofisticat într-un mod sănătos.",
        ingredients: [
            "caju crud",
            "fistic crud nesărat",
            "ulei de cocos extra virgin",
            "sirop de arțar",
            "băutură de cocos",
            "pasta vegetala de cacao",
            "faina de migdale",
            "unt de cocos",
            "extract pur de vanilie",
            "spanac",
            "sare"
        ],
        variants: [
            {
                name: "Standard",
                weight: "1.100 gr",
                price: "310,00 Lei",
                pricePerUnit: "29 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },
    "Tort Unicorn": {
        description: "Tortul cu Cremă de lămâie, crema de capsuni, crema de afine și maqui berry este perfect pentru iubitorii de deserturi fructate și pentru copii. Combinând arome proaspete de lămâie, capsuni, afine și maqui berry, acest tort oferă o experiență gustativă vibrantă și echilibrată. Ideal pentru a adăuga un strop de rafinament și savoare oricărei ocazii speciale.",
        ingredients: [
            "caju",
            "migdale",
            "curmale",
            "sirop de arțar",
            "căpșuni",
            "pasta vegetala de cacao",
            "ulei de cocos",
            "pasta vegetala de cocos",
            "lămâie",
            "extract pur de vanilie",
            "afine",
            "pudra Maqui berry",
            "bautura de cocos",
            "zmeura",
            "eritritol",
            "sare"
        ],
        variants: [
            {
                name: "Tort simplu, fara decor si fara frosting",
                weight: "1.700 gr",
                price: "510 lei",
                pricePerUnit: "30 lei/100gr"
            },
            {
                name: "Tort Unicorn cu frosting si velur efect de catifea, FARA horn si FARA urechi comestibile",
                weight: "2.450 gr",
                price: "735 lei",
                pricePerUnit: "30 lei/100gr"
            },
            {
                name: "Tort Unicorn complet, cu horn si urechi comestibile",
                weight: "2.800 gr",
                price: "840 lei",
                pricePerUnit: "30 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },
    "Tortul Duo Ciocolată-Portocală cu gust de iarnă": {
        description: "Tortul Ciocolată-Portocală cu Gust de iarnă îmbină perfect aroma intensă a ciocolatei cu prospețimea vibrantă a portocalei, evocând magia sărbătorilor de iarnă. Fiecare felie aduce un echilibru de arome calde și revigorante, ideale pentru momentele de răsfăț în sezonul rece. Un desert elegant, perfect pentru mesele de sărbătoare.",
        ingredients: [
            "caju",
            "portocale",
            "sirop de arțar",
            "ulei de cocos",
            "migdale deshidratate",
            "stafide",
            "pasta vegetala de cocos",
            "cacao",
            "pasta vegetala de cacao",
            "extract pur de vanilie",
            "sare",
            "turmeric"
        ], variants: [
            {
                name: "Standard",
                weight: "1 KG",
                price: "300,00 Lei",
                pricePerUnit: "30 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },
    "Tartă cu căpșuni și fistic": {
        description: "Tarta cu căpșuni și fistic, vegană, este un desert delicat și rafinat, care combină prospețimea căpșunilor cu aroma intensă a fisticului. Fiecare bucățică oferă o textură crocantă și cremoasă, fără ingrediente de origine animală. Ideală pentru iubitorii de dulciuri sănătoase și pline de savoare.",
        ingredients: [
            "curmale",
            "cocos",
            "capsuni",
            "caju",
            "sirop de arțar",
            "ulei de cocos",
            "seminte de in",
            "lămâie",
            "pasta vegetala de cacao",
            "sare"
        ],
        variants: [
            {
                name: "Standard",
                weight: "850 gr",
                price: "240,00 Lei",
                pricePerUnit: "29 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },
    "Tartă cu mascarpone - decorată cu fructe la alegere": {
        description: "Tarta cu mascarpone vegană combină o umplutură cremoasă și delicioasă cu o crustă crocantă. Cu o notă subtilă de brânză și fructe dulci, aceasta oferă un echilibru perfect între sofisticat și fructat, fiind ideală pentru iubitorii de deserturi vegane care apreciază o notă ușor de brânză. Perfectă pentru a adăuga un strop de plăcere în fiecare ocazie.",
        ingredients: [
            "caju",
            "curmale",
            "ulei de cocos",
            "lămâie",
            "sirop arțar",
            "extract de vanilie",
            "drojdie inactiva",
            "sare"
        ],
        variants: [
            {
                name: "Standard - decorată cu fructe la alegere",
                weight: "950 gr",
                price: "270,00 Lei",
                pricePerUnit: "29 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },
    "Trufe cu alune": {
        description: "Trufele vegane cu alune sunt un deliciu rafinat, creat din ingrediente naturale și 100% vegetale. Cu o textură catifelată și aroma intensă a alunelor crocante, aceste trufe oferă un echilibru perfect între gust și sănătate. Ideale pentru un răsfăț dulce fără compromisuri.",
        ingredients: [
            "sirop de arțar",
            "pasta vegetala de migdale",
            "alune",
            "pasta vegetala de cacao",
            "cacao",
            "extract pur de vanilie",
            "sare"
        ],
        variants: [
            {
                name: "Trufe invelite cu alune",
                weight: "1 bucata",
                price: "8,00 Lei",
                pricePerUnit: "8 lei/bucata"
            },
            {
                name: "Trufe invelite cu migdale",
                weight: "1 bucata",
                price: "8,00 Lei",
                pricePerUnit: "8 lei/bucata"
            },
            {
                name: "Trufe invelite in ciocolata",
                weight: "1 bucata",
                price: "8,00 Lei",
                pricePerUnit: "8 lei/bucata"
            },
            {
                name: "Trufe invelite in pudra de cacao",
                weight: "1 bucata",
                price: "8,00 Lei",
                pricePerUnit: "8 lei/bucata"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },
    "Biscuiți Pădurea Neagră cu umplutură de Cookie Cream": {
        description: "Biscuiții Pădurea Neagră sunt o delicatesă vegană ce combină rafinamentul și gustul autentic, realizat din ingrediente naturale și fără produse de origine animală. Fiecare mușcătură oferă o experiență armonioasă de arome, fiind un desert ideal pentru cei mici și pentru cei mari.",
        ingredients: [
            "Caju",
            "curmale",
            "pasta vegetala de cocos",
            "pasta vegetala de migdale",
            "sirop de arțar",
            "ulei de cocos extravirgin",
            "faina de migdale",
            "pudra de cacao",
            "extract de vanilie",
            "sare"
        ],
        variants: [
            {
                name: "Cu umplutură de cookie cream",
                weight: "100 gr",
                price: "29,00 Lei",
                pricePerUnit: "29 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },
    "Fudge cu ciocolata albă și zmeura": {
        description: "Fudge-ul cu ciocolată albă și zmeură îmbină subtilitatea ciocolatei albe cu prospețimea vibrantă a zmeurei, oferind o textură fermă, dar rafinată. Echilibrul perfect între dulceață și notele ușor acrișoare conferă o experiență gustativă intensă și memorabilă. Acest desert sofisticat este ideal pentru cei care apreciază combinațiile elegante și pline de personalitate.",
        ingredients: [
            "pasta veegetala de cocos",
            "sirop de arțar",
            "caju",
            "pasta vegetala de cacao",
            "faina de cocos",
            "extract de vanilie",
            "sare"
        ],
        variants: [
            {
                name: "Standard",
                weight: "100 gr",
                price: "29,00 Lei",
                pricePerUnit: "29 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },

    "Fudge cu caramel și ciocolata": {
        description: "Un desert elegant și rafinat, cu o textură cremoasă și catifelată, care îmbină perfect dulceața caramelului cu subtilitatea ciocolatei fine.",
        ingredients: [
            "ulei de cocos extravirgin",
            "sirop de arțar",
            "Unt de migdale",
            "unt de cocos",
            "extract de vanilie",
            "pudra de cacao",
            "scortisoara Ceylon",
            "sare"
        ],
        variants: [
            {
                name: "Standard",
                weight: "100 gr",
                price: "29,00 Lei",
                pricePerUnit: "29 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },

    "Tartă KETO cu Ciocolată și Migdale": {
        description: "Tarta vegana cu ciocolată și migdale, ideală pentru dietele keto, este un răsfăț delicios care combină textura crocantă a migdalelor cu ciocolata bogată și cremoasă. Fiecare mușcătură oferă o experiență satisfăcătoare fără compromisuri dietetice. Ideală pentru a savura un desert sănătos și plin de savoare!",
        ingredients: [
            "pasta vegetala de migdale",
            "pasta vegetala de cocos",
            "băutură de migdale",
            "ulei de cocos",
            "migdale",
            "cacao",
            "eritritol",
            "extract pur de vanilie",
            "cocos",
            "stevie",
            "sare"
        ],
        variants: [
            {
                name: "Standard",
                weight: "950 gr",
                price: "285,00 Lei",
                pricePerUnit: "30 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    },

    "Tartă Gourmet cu Ciocolată și Migdale": {
        description: "Tarta gourmet vegana cu ciocolată și migdale este un răsfăț de neuitat, combinând ciocolata cremoasă cu crocantul migdalelor. Fiecare mușcătură oferă o plăcere desăvârșită, perfectă pentru cei care caută un desert delicios și sofisticat. Ideală pentru a adăuga un strop de savoare în orice moment al zilei!",
        ingredients: [
            "pasta vegetala de migdale",
            "ulei de cocos",
            "migdale",
            "pasta vegetala de cocos",
            "curmale",
            "sirop de artar",
            "bautura de migdale",
            "cacao",
            "extract pur de vanilie",
            "cocos",
            "stevie",
            "sare"
        ],
        variants: [
            {
                name: "Standard",
                weight: "950 gr",
                price: "285,00 Lei",
                pricePerUnit: "30 lei/100gr"
            }
        ],
        orderInfo: [
            "Comenzile pentru torturile fără decor personalizat se plasează cu minim 48 de ore înainte de ziua livrării.",
            "Pentru torturile decorate, comenzile se plasează cu minim 72h înainte de ziua livrării."
        ]
    }
};

export default productDescriptions; 