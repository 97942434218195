import React, { useState } from 'react';
import { Navbar, Nav, Container, Modal } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { motion, AnimatePresence } from 'framer-motion';
import { FaHeart, FaCookie, FaTimes } from 'react-icons/fa';

function NavigationBar() {
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showServices, setShowServices] = useState(false);

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { 
        type: "spring",
        duration: 0.5,
        bounce: 0.4
      }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.3 }
    }
  };

  return (
    <>
      <Navbar expand="lg" className="custom-navbar" sticky="top">
        <Container fluid="md">
          <Navbar.Brand href="/" className="navbar-brand-custom">
            <img
              src="/images/logo.png"
              alt="Raw Da Bun Logo"
              className="navbar-logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link 
                as={Link}
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={100}
                className="nav-link-custom"
              >
                Home
              </Nav.Link>
              <Nav.Link 
                onClick={() => setShowAboutUs(true)}
                className="nav-link-custom"
              >
                Despre Noi
              </Nav.Link>
              <Nav.Link 
                onClick={() => setShowServices(true)}
                className="nav-link-custom"
              >
                Servicii
              </Nav.Link>
              <Nav.Link 
                as={Link}
                to="products"
                spy={true}
                smooth={true}
                offset={-70}
                duration={100}
                className="nav-link-custom"
              >
                Produse
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <AnimatePresence>
        {showAboutUs && (
          <Modal 
            show={showAboutUs} 
            onHide={() => setShowAboutUs(false)}
            centered
            className="custom-modal"
            size="lg"
          >
            <motion.div
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <Modal.Header className="border-0">
                <Modal.Title className="w-100 text-center">
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", bounce: 0.6 }}
                  >
                    <FaHeart className="modal-icon pulse-animation" />
                  </motion.div>
                  <h4>Povestea Noastră</h4>
                </Modal.Title>
                <button 
                  className="modal-close-btn"
                  onClick={() => setShowAboutUs(false)}
                >
                  <FaTimes />
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="modal-content-wrapper">
                  <motion.div
                    className="story-section"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    <p>
                      Bine ați venit în casa virtuală Raw da' bun.<br /><br />
                      Eu sunt Andreea, Chef Cofetar Vegan, pasionată de dulciurile sănătoase.<br /><br />
                      Am descoperit deserturile raw vegane atunci când am hotărât să elimin zahărul din alimentația zilnică. 
                      Așa am învățat că există atât de multe alternative nutritive și delicioase.
                    </p>
                  </motion.div>

                  <motion.div
                    className="story-section"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                  >
                    <p>
                      Motivația a crescut și mai mult datorită băiețelului nostru, alergic la ouă, lactoză, soia, 
                      arahide, făină de grâu și secară. O adevărată distracție. Pe lângă lista lungă de alergeni, 
                      aveam o listă și mai lungă de alimente la care era intolerant.<br /><br />
                      În acea perioadă, dulciurile raw vegane au fost o adevărată binecuvântare, adaptându-se perfect nevoilor lui.
                    </p>
                  </motion.div>

                  <motion.div
                    className="values-section"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                  >
                    <p>
                      Ne dorim să oferim tuturor o alternativă sănătoasă și delicioasă la produsele convenționale. 
                      Suntem aici pentru copii, diabetici, vegani, persoane cu alergii și intoleranțe alimentare, 
                      dar și pentru oricine dorește să exploreze opțiuni sănătoase și pline de nutrienți.
                    </p>
                  </motion.div>
                </div>
              </Modal.Body>
            </motion.div>
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showServices && (
          <Modal 
            show={showServices} 
            onHide={() => setShowServices(false)}
            centered
            className="custom-modal"
            size="lg"
          >
            <motion.div
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <Modal.Header className="border-0">
                <Modal.Title className="w-100 text-center">
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", bounce: 0.6 }}
                  >
                    <FaCookie className="modal-icon pulse-animation" />
                  </motion.div>
                  <h4>Serviciile Noastre</h4>
                </Modal.Title>
                <button 
                  className="modal-close-btn"
                  onClick={() => setShowServices(false)}
                >
                  <FaTimes />
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="modal-content-wrapper">
                  <motion.div
                    className="service-card"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    <p>
                      Salut, suntem echipa RAW DA' BUN și vrem să aducem zâmbete și sănătate în viața ta, 
                      oferind o gamă de torturi și dulciuri raw vegane delicioase și pline de nutrienți.<br /><br />
                      Cum afli dacă ești în locul potrivit? Îți spun chiar acum. Suntem aici pentru tine dacă 
                      ești sportiv atent la alimentație sau poate ai un copilaș și vrei să te asiguri că mănâncă 
                      doar produse de calitate, îndulcitori naturali, coloranți din pudră naturală și fără conservanți.
                    </p>
                  </motion.div>

                  <motion.div
                    className="service-card"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                  >
                    <p>
                      Punem accent pe calitate și alegem să folosim produse naturale pentru că ne dorim să oferim 
                      o experiență cu un gust delicios, pentru o digestie ușoară și bogate în nutrienți pentru toți 
                      cei care sunt atenți la ceea ce mănâncă.<br /><br />
                      Pe bază de plante, fără gluten, fără soia, îndulcite cu produse naturale, cum ar fi nectar 
                      de cocos, sirop de arțar, fructe proaspete și fructe de pădure. Pentru diabetici folosim 
                      ștevie pură sau eritritol.
                    </p>
                  </motion.div>

                  <motion.div
                    className="service-card"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                  >
                    <p>
                      Super alimentele transformă torturile și dulciurile într-o masă/gustare plină de nutrienți: 
                      pudră Maqui berry, pudră mesquite, spirulina, turmeric, cacao, ceai verde Matcha, fructe 
                      de pădure goji și semințe de in.<br /><br />
                      Cu noi, savurezi mai mult decât un desert. Echipa RAW DA' BUN iubește să aducă calitatea 
                      și sănătatea în bucătăria celorlalți prin deserturile și torturile noastre, pe care le 
                      creăm cu pasiune în fiecare zi.
                    </p>
                  </motion.div>
                </div>
              </Modal.Body>
            </motion.div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}

export default NavigationBar;