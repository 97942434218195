import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

function WhatsAppButton() {
    const phoneNumber = "+40770816466"; // Replace with your actual number
    const whatsappUrl = `https://wa.me/${phoneNumber.replace(/[^0-9]/g, '')}`;

    return (
        <a 
            href={whatsappUrl} 
            className="whatsapp-button"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Chat on WhatsApp"
        >
            <FaWhatsapp className="whatsapp-icon" />
            <span className="tooltip">Scrie-ne pe WhatsApp</span>
        </a>
    );
}

export default WhatsAppButton;