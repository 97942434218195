import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';

function Footer() {
    return (
        <footer className="site-footer">
            <Container>
                <Row className="justify-content-between">
                    <Col md={3} className="footer-section">
                        <h5>Despre Noi</h5>
                        <p>
                            Raw Da Bun este un atelier de cofetărie artizanală, 
                            specializat în crearea de deserturi raw-vegane delicioase și sănătoase.
                        </p>
                    </Col>

                    <Col md={3} className="footer-section">
                        <h5>Contact</h5>
                        <ul className="contact-list">
                            <li>
                                <FaPhone className="icon" />
                                <a href="tel:0770 816 466">0770 816 466</a>
                            </li>
                            <li>
                                <FaEnvelope className="icon" />
                                <a href="mailto:contact@rawdabun.ro">contact@rawdabun.ro</a>
                            </li>
                            <li>
                                <FaMapMarkerAlt className="icon" />
                                <a 
                                    href="https://www.google.com/maps/search/?api=1&query=Strada+Vasile+Maior+Bacila+35+Sector+2+București" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    Strada Vasile Maior Bacila, nr 35, Sector 2, București
                                </a>
                            </li>
                        </ul>
                    </Col>

                    <Col md={3} className="footer-section">
                        <h5>Urmărește-ne</h5>
                        <div className="social-links">
                            <a href="https://www.facebook.com/profile.php?id=61567041394388" target="_blank" rel="noopener noreferrer">
                                <FaFacebook className="social-icon" />
                            </a>
                            <a href="https://www.instagram.com/rawdabun/profilecard/?igsh=MW41NzdjbGM3ZnJkYw==" target="_blank" rel="noopener noreferrer">
                                <FaInstagram className="social-icon" />
                            </a>
                            <a href='https://www.tiktok.com/@rawdabun?_t=8r51TicuIrO&_r=1' target="_blank" rel="noopener noreferrer">
                                <FaTiktok className="social-icon" />
                            </a>
                        </div>
                    </Col>

                    <Col md={3} className="footer-section">
                        <h5>Locație</h5>
                        <div className="map-container">
                            <iframe
                                title="Raw Da Bun Location"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2848.8885076766424!2d26.159699776891752!3d44.43679997107039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1f8c2d365d3b7%3A0x8f79d0c0f87432d0!2sStrada%20Vasile%20Maior%20B%C4%83cil%C4%83%2035%2C%20Bucure%C8%99ti!5e0!3m2!1sen!2sro!4v1709414547943!5m2!1sen!2sro"
                                width="100%"
                                height="200"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className="text-center copyright">
                        <p>&copy; {new Date().getFullYear()} Raw Da Bun. Toate drepturile rezervate.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;