import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Card, Carousel, Modal, Button } from 'react-bootstrap';
import { productDescriptions } from './productDescriptions';
import ContactFormModal from './ContactFormModal';

function Products() {
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const productsGridRef = useRef(null);
    const menuRef = useRef(null);
    const [menuClosedHeight, setMenuClosedHeight] = useState(0);
    const [showContactForm, setShowContactForm] = useState(false);

    useEffect(() => {
        if (menuRef.current) {
            const toggleButton = menuRef.current.querySelector('.menu-toggle');
            if (toggleButton) {
                setMenuClosedHeight(toggleButton.offsetHeight);
            }
        }
    }, []);

    const handleShowModal = (product) => {
        setSelectedProduct(product);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedProduct(null);
    };

    const handleCategorySelect = (categoryId) => {
        setSelectedCategory(categoryId);
        setIsMenuOpen(false);

        setTimeout(() => {
            if (menuRef.current) {
                const menuTop = menuRef.current.getBoundingClientRect().top;
                const scrollPosition = window.pageYOffset + menuTop + menuClosedHeight;

                window.scrollTo({
                    top: scrollPosition,
                    behavior: 'smooth'
                });
            }
        }, 50);
    };

    const categories = [
        { id: 'all', name: 'Toate' },
        {
            id: 'torturi', name: 'Torturi', subcategories: [
                { id: 'torturi-premium', name: 'Torturi Premium' },
                { id: 'torturi-fructe', name: 'Torturi Fructe' },
                { id: 'torturi-tematice', name: 'Torturi Tematice' },
                { id: 'torturi-keto', name: 'Torturi KETO' },
                { id: 'torturi-copii', name: 'Torturi pentru copii' }
            ]
        },
        {
            id: 'tarte', name: 'Tarte', subcategories: [
                { id: 'tarte-fructe', name: 'Tarte Fructe' },
                { id: 'tarte-keto', name: 'Tarte KETO' }
            ]
        },
        { id: 'mini-delicii', name: 'Mini delicii' },
        { id: 'prajituri', name: 'Prajituri' }
    ];

    const products = [
        {
            id: 1,
            name: "Tort Unicorn",
            images: [
                "/images/Tort Unicorn1.png",
                "/images/Tort Unicorn2.png",
                "/images/Tort Unicorn3.png",
                "/images/Tort Unicorn4.png",
                "/images/Tort Unicorn5.png"
            ],
            category: "Torturi",
            description: "Tortul cu Cremă de lămâie, crema de capsuni, crema de afine și maqui berry este perfect pentru iubitorii de deserturi fructate și pentru copii. Combinând arome proaspete de lămâie, capsuni, afine și maqui berry, acest tort oferă o experiență gustativă vibrantă și echilibrată. Ideal pentru a adăuga un strop de rafinament și savoare oricărei ocazii speciale.Ingrediente caju, migdale, curmale, sirop de arțar, căpșuni, pasta vegetala de cacao, ulei de cocos, pasta vegetala de cocos, lămâie, extract pur de vanilie, afine, pudra Maqui berry, bautura de cocos, zmeura, eritritol, sareVarianteTort Unicorn simplu, fara decor - 1700g | Full Price: 399 RON | Price per 100g: 24 RONTort Unicorn cu frosting, FARA horn si FARA urechi comestibile - 2450g | Full Price: 589 RON | Price per 100g: 24 RON Tort Unicorn complet, cu horn si urechi comestibile - 2800g | Full Price: 699 RON | Price per 100g: 24 RON"
        },
        {
            id: 2,
            name: "Trufe cu alune",
            images: ["/images/Trufe cu alune.png"], // Convert single image to array
            category: "Dulciuri",
        },
        {
            id: 3,
            name: "Tortul Duo Ciocolată-Portocală cu gust de iarnă",
            images: ["/images/Tortul Duo Ciocolata-Portocala.png"],
            category: "Torturi",
        },
        // ... Convert all other products to use images array
        {
            id: 4,
            name: "Tartă Gourmet cu Ciocolată și Migdale",
            images: ["/images/Tarta Gourmet.png"],
            category: "Prăjituri",
        },
        {
            id: 5,
            name: "Tartă KETO cu Ciocolată și Migdale",
            images: ["/images/Tarta Keto.png"],
            category: "Prăjituri",
        },
        {
            id: 6,
            name: "Tartă cu căpșuni și fistic",
            images: ["/images/Tarta cu capsuni si fistic1.png",
                "/images/Tarta cu capsuni si fistic2.png"
            ],
            category: "Dulciuri",
        },
        {
            id: 7,
            name: "Tartă cu mascarpone - decorată cu fructe la alegere",
            images: ["/images/Tarta mascarpone1.png",
                "/images/Tarta mascarpone2.png",
                "/images/Tarta mascarpone3.png"
            ],
            category: "Dulciuri",
        },
        {
            id: 8,
            name: "Tort Zebra cu Afine",
            images: ["/images/Tort Zebra cu Afine1.png",
                "/images/Tort Zebra cu Afine2.png",
            ],
            category: "Torturi",
        },
        {
            id: 9,
            name: "Tort Mickey Mouse",
            images: ["/images/Tort Mickey Mouse1.png",
                "/images/Tort Mickey Mouse2.png",
                "/images/Tort Mickey Mouse3.png",
                "/images/Tort Mickey Mouse4.png",
                "/images/Tort Mickey Mouse5.png",
                "/images/Tort Mickey Mouse6.png",
                "/images/Tort Mickey Mouse7.png"
            ],
            category: "Torturi",
        },
        {
            id: 10,
            name: "Tiramisu",
            images: ["/images/Tiramisu.png"],
            category: "Prăjituri",
        },
        {
            id: 11,
            name: "Tort Zebra cu Fistic și Ciocolată Albă - KETO",
            images: [
                "/images/Tort Zebra cu Fistic si Ciocolata Alba1.png",
                "/images/Tort Zebra cu Fistic si Ciocolata Alba2.png",
                "/images/Tort Zebra cu Fistic si Ciocolata Alba3.png",
            ],
            category: "Torturi",
        },
        {
            id: 12,
            name: "Tort Trio de Ciocolată",
            images: ["/images/Tort Trio de Ciocolata1.png",
                "/images/Tort Trio de Ciocolata2.png",
                "/images/Tort Trio de Ciocolata3.png"
            ],
            category: "Prăjituri",
        },
        {
            id: 13,
            name: "Tort cu Caramel Sărat și Ciocolată",
            images: ["/images/Tort cu Caramel Sarat si Ciocolata1.png",
                "/images/Tort cu Caramel Sarat si Ciocolata2.png"
            ],
            category: "Torturi",
        },
        {
            id: 14,
            name: "Tort cu Caramel Sărat și Migdale",
            images: ["/images/Tort cu Caramel Sarat si Migdale1.png",
                "/images/Tort cu Caramel Sarat si Migdale2.png",
                "/images/Tort cu Caramel Sarat si Migdale3.png",
            ],
            category: "Torturi",
        },
        {
            id: 15,
            name: "Biscuiți Pădurea Neagră cu umplutură de Cookie Cream",
            images: ["/images/Biscuiti padurea neagra cu umplutura de cookie cream1.png",
                "/images/Biscuiti padurea neagra cu umplutura de cookie cream2.png",
                "/images/Biscuiti padurea neagra cu umplutura de cookie cream3.png",
                "/images/Biscuiti padurea neagra cu umplutura de cookie cream4.png",

            ],
            category: "Dulciuri",
        },
        {
            id: 16,
            name: "Fudge cu ciocolata albă și zmeura",
            images: ["/images/Fudge cu ciocolata alba si zmeura1.png",
                "/images/Fudge cu ciocolata alba si zmeura2.png",
                "/images/Fudge cu ciocolata alba si zmeura3.png",
                "/images/Fudge cu ciocolata alba si zmeura4.png"
            ],
            category: "Prăjituri",
        },
        {
            id: 17,
            name: "Fudge cu caramel și ciocolata",
            images: ["/images/Fudge cu caramel si ciocolata1.png"
            ],
            category: "Prăjituri",
        }
    ];

    const productCategories = {
        'Tort Unicorn': ['torturi-premium', 'torturi-fructe', 'torturi-tematice', 'torturi-copii'],
        'Tort Zebra cu Afine': ['torturi-fructe', 'torturi-copii'],
        'Tortul Duo Ciocolată-Portocală cu gust de iarnă': ['torturi-fructe', 'torturi-copii'],
        'Tort Mickey Mouse': ['torturi-premium', 'torturi-tematice', 'torturi-copii'],
        'Tort Zebra cu Fistic și Ciocolată Albă - KETO': ['torturi-keto'],
        'Tort Trio de Ciocolată': ['torturi-premium', 'torturi-tematice', 'torturi-copii'],
        'Tort cu Caramel Sărat și Ciocolată': ['torturi-premium', 'torturi-copii'],
        'Tort cu Caramel Sărat și Migdale': ['torturi-premium', 'torturi-copii'],
        'Tartă Gourmet cu Ciocolată și Migdale': ['tarte-fructe', 'torturi-copii'],
        'Tartă cu căpșuni și fistic': ['tarte-fructe', 'torturi-copii'],
        'Tartă cu mascarpone - decorată cu fructe la alegere': ['tarte-fructe', 'torturi-copii'],
        'Tartă KETO cu Ciocolată și Migdale': ['tarte-keto'],
        'Trufe cu alune': ['mini-delicii'],
        'Biscuiți Pădurea Neagră cu umplutură de Cookie Cream': ['mini-delicii'],
        'Fudge cu ciocolata albă și zmeura': ['mini-delicii'],
        'Fudge cu caramel și ciocolata': ['mini-delicii'],
        'Tiramisu': ['torturi-premium']
    };

    const filteredProducts = products.filter(product => {
        if (selectedCategory === 'all') return true;
        const productCats = productCategories[product.name] || [];
        return productCats.includes(selectedCategory);
    });

    const ProductImages = ({ images }) => {
        const [index, setIndex] = useState(0);

        const handleSelect = (selectedIndex) => {
            setIndex(selectedIndex);
        };

        if (!images || images.length === 0) {
            return null;
        }

        if (images.length === 1) {
            return (
                <Card.Img
                    variant="top"
                    src={images[0]}
                    alt="Product"
                    className="product-image"
                />
            );
        }

        return (
            <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                interval={null}
                className="product-carousel"
                indicators={true}
                controls={true}
            >
                {images.map((image, idx) => (
                    <Carousel.Item key={idx}>
                        <img
                            className="d-block w-100 product-image"
                            src={image}
                            alt={`Slide ${idx + 1}`}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        );
    };

    const ProductCard = ({ product }) => {
        const productInfo = productDescriptions[product.name];
        const baseVariant = productInfo?.variants[0]; // Get first variant as default

        return (
            <Card className="product-card w-100">
                <div className="product-image-wrapper">
                    <ProductImages images={product.images} />
                    <div className="product-overlay">
                        <button
                            className="view-button"
                            onClick={() => handleShowModal(product)}
                        >
                            Vezi Detalii
                        </button>
                    </div>
                </div>
                <Card.Body>
                    <span className="product-category">{product.category}</span>
                    <Card.Title className="product-name">{product.name}</Card.Title>
                    {baseVariant && (
                        <div className="product-price-info">
                            <span className="price">{baseVariant.price}</span>
                            <span className="weight">/ {baseVariant.weight}</span>
                        </div>
                    )}
                    {product.images && product.images.length > 1 && (
                        <span className="image-count">
                            {product.images.length} fotografii
                        </span>
                    )}
                </Card.Body>
            </Card>
        );
    };

    const ProductModal = ({ product, show, onHide }) => {
        const productInfo = productDescriptions[product?.name];

        if (!productInfo) return null;

        return (
            <>
                <Modal show={show} onHide={onHide} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{product.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="product-details">
                            <div className="description-section">
                                <p>{productInfo.description}</p>
                            </div>

                            <div className="ingredients-section">
                                <h5>Ingrediente:</h5>
                                <div className="ingredients-grid">
                                    {productInfo.ingredients.map((ingredient, index) => (
                                        <div key={index} className="ingredient-item">
                                            • {ingredient}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="variants-section">
                                <h5>Variante disponibile:</h5>
                                {productInfo.variants.map((variant, index) => (
                                    <div key={index} className="variant-item">
                                        <h6>{variant.name}</h6>
                                        <div className="variant-details">
                                            <span className="weight">{variant.weight}</span>
                                            <span className="price">{variant.price}</span>
                                            <span className="price-per-unit">{variant.pricePerUnit}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="order-info-section">
                                <h5>Informații comandă:</h5>
                                {productInfo.orderInfo.map((info, index) => (
                                    <p key={index}>{info}</p>
                                ))}
                            </div>
                        </div>

                        <div className="contact-section">
                            <Button
                                onClick={() => handleShowContactForm(product)}
                                className="btn-custom-coffee w-100"
                            >
                                Contactează-ne pentru acest produs
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <ContactFormModal
                    show={showContactForm}
                    onHide={() => setShowContactForm(false)}
                    productName={product?.name}
                />
            </>
        );
    };

    const handleShowContactForm = (product) => {
        // First close the product details modal
        setShowModal(false);

        // Then open the contact form after a small delay
        setTimeout(() => {
            setShowContactForm(true);
        }, 300);
    };

    return (
        <section className="products-section">
            <Container>
                {/* Wrap both menu toggle and category menu in one div with ref */}
                <div ref={menuRef} className="menu-container">
                    <div className="menu-toggle">
                        <button
                            className="toggle-btn"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                        >
                            {isMenuOpen ? 'Ascunde Categoriile ▼' : 'Arată Categoriile ▲'}
                        </button>
                    </div>

                    <div className={`category-menu ${isMenuOpen ? 'open' : 'closed'}`}>
                        {categories.map(category => (
                            <div key={category.id} className="category-group">
                                {category.id === 'all' ? (
                                    <button
                                        className={`category-btn ${selectedCategory === 'all' ? 'active' : ''}`}
                                        onClick={() => handleCategorySelect('all')}
                                    >
                                        {category.name}
                                    </button>
                                ) : !category.subcategories ? (
                                    <button
                                        className={`category-btn ${selectedCategory === category.id ? 'active' : ''}`}
                                        onClick={() => handleCategorySelect(category.id)}
                                    >
                                        {category.name}
                                    </button>
                                ) : (
                                    <div className="dropdown">
                                        <button className="category-btn dropdown-toggle">
                                            {category.name}
                                        </button>
                                        <div className="dropdown-menu">
                                            {category.subcategories.map(sub => (
                                                <button
                                                    key={sub.id}
                                                    className={`dropdown-item ${selectedCategory === sub.id ? 'active' : ''}`}
                                                    onClick={() => handleCategorySelect(sub.id)}
                                                >
                                                    {sub.name}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                {/* Products Grid */}
                <Row ref={productsGridRef} className="g-4 justify-content-center products-grid">
                    {filteredProducts.map((product) => (
                        <Col key={product.id} xs={12} sm={6} lg={4} className="d-flex">
                            <ProductCard product={product} />
                        </Col>
                    ))}
                </Row>

                <ProductModal
                    product={selectedProduct}
                    show={showModal}
                    onHide={handleCloseModal}
                />
            </Container>
        </section>
    );
}

export default Products;
