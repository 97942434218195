import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ContactFormModal = ({ show, onHide, productName }) => {
    const handleContact = () => {
        // Compose Gmail URL with pre-filled subject
        const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=contact@rawdabun.ro&su=Cerere produs: ${productName}`;
        
        // Open Gmail in new tab
        window.open(gmailUrl, '_blank');
        
        // Close the modal
        onHide();
    };

    return (
        <Modal 
            show={show} 
            onHide={onHide}
            className="contact-form-modal"
            centered
            dialogClassName="modal-90w"
        >
            <Modal.Header closeButton>
                <Modal.Title>Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center mb-4">
                    <p className="mb-2">Pentru detalii despre produsul:</p>
                    <h5 className="selected-product mb-3">{productName}</h5>
                    <p>Click pe butonul de mai jos pentru a ne contacta prin email:</p>
                </div>
                
                <Button 
                    onClick={handleContact}
                    className="btn-custom-coffee w-100"
                >
                    Deschide Gmail
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default ContactFormModal;