import React from 'react';
import NavigationBar from './components/NavigationBar';
import Home from './components/Home';
import Products from './components/Products';
import Footer from './components/Footer';
import WhatsAppButton from './components/WhatsAppButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/custom.css';

function App() {
  return (
    <div className="app">
      <NavigationBar />
      <section id="home" className="section">
        <Home />
      </section>
      <section id="products" className="section">
        <Products />
      </section>
      <Footer />
      <WhatsAppButton />
    </div>
  );
}

export default App;